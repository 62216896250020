import {
  createEffect,
  createMemo,
  createSignal,
  For,
  lazy,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { useWindowSize } from "@solid-primitives/resize-observer";
import { triggerResizeEvent } from "~/utils/map_utils";
import Breadcrumb from "~/components/shared/Breadcrumb";
import ResultsSEO from "~/components/Search/Components/ResultsSEO";

import type { BreadcrumbItem } from "~/types/breadcrumb";
import type {
  ProgramRefWithLocation,
  SearchResults,
} from "~/types/drupal_jsonapi";

import "~/components/shared/SearchResults.css";
import "./ResultsPage.css";

import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import ProgramsList from "../shared/ProgramsList";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { clientOnly } from "@solidjs/start";
import { formatPlural, formatPrice } from "~/utils/format";
import { createStore } from "solid-js/store";
import { ITEMS_PER_PAGE } from "~/utils/constants";
import PitchimmoBanner from "../shared/PitchimmoBanner";
import { useLocation } from "@solidjs/router";
import { useSearchContext } from "~/contexts/SearchContext";
import { ProgramsListSearchResults } from "../shared/ProgramsList";
import { useLotActive } from "~/contexts/LotActiveContext";

const Map = clientOnly(() => import("~/components/shared/Map"));
const LotDrawer = lazy(
  () => import("~/components/Program/Components/LotDrawer"),
);

const [viewList, setViewList] = createSignal(true);
const [viewMixed, setViewMixed] = createSignal(false);
const [viewMap, setViewMap] = createSignal(false);

type ResultsStore = {
  markers: ProgramRefWithLocation[];
  slices: ProgramRefWithLocation[][];
  displayedPrograms: ProgramRefWithLocation[];
  currentPage: number;
};

const [store, setStore] = createStore<ResultsStore>({
  markers: [],
  slices: [],
  displayedPrograms: [],
  currentPage: 0,
});

export default function ResultsPage(props: { results: SearchResults }) {
  const loc = useLocation();
  const settings = useDrupalSettingsContext();
  const [viewportSizeProvider] = useViewportSizeContext();
  const size = useWindowSize();
  const [, searchActions] = useSearchContext();

  const attachExternalLinksBehavior = function () {
    for (let c = document.getElementsByTagName("a"), a = 0; a < c.length; a++) {
      const b = c[a];
      const isExt =
        b.getAttribute("href") &&
        (b.protocol === "https:" || b.protocol === "http") &&
        b.hostname !== location.hostname;

      const isAltarea = b.textContent!.toLowerCase().includes("altarea");

      if (isExt || isAltarea) {
        b.target = "_blank";
        b.classList.add("ext");
        b.setAttribute("rel", "noopener noreferrer nofollow");
      }
    }
  };

  onMount(() => attachExternalLinksBehavior());

  /* Open TopBar SearchForm by default on desktop only */
  onMount(() => {
    if (size.width > 768) {
      searchActions.setSearchBarOpen!();
    }

    searchActions.setSearchResults!(props.results);
  });

  /* Hide TopBar SearchForm on resize if mobile device */
  createEffect(() => {
    if (size.width > 768) {
      searchActions.setSearchBarOpen!();
    } else {
      searchActions.setSearchBarClose!();
    }
  });

  createEffect(() => {
    // On load, we set the proper layout based on resolution.
    // We don't want small devices to have tha map loaded by default.
    if (size.width > 768) {
      setViewList(false);
      setViewMixed(true);
    }
  });

  createEffect(() => {
    viewList();
    viewMap();
    viewMixed();

    setTimeout(() => {
      triggerResizeEvent();
    }, 400);
  });

  const showPromoCard = () => {
    return settings.promo_card.disp_search;
  };

  setStore("slices", () => {
    if (props.results.programs?.length > 0) {
      const slices = Array.from(
        {
          length: Math.ceil(props.results.programs.length / ITEMS_PER_PAGE),
        },
        (_, i) => {
          let p = props.results.programs;

          // Clone last item of first page to push it to the next page
          if (settings.promo_card.disp_search) {
            p = p!.toSpliced(6, 0, p![5]);
          }

          return p!.slice(
            i * ITEMS_PER_PAGE,
            i * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
          );
        },
      );

      // On the first page, remove the last item to display the promo card
      if (showPromoCard() && slices.length > 1) {
        slices.at(0)!.pop();
        return slices;
      }
      return slices;
    } else {
      return [[]];
    }
  });

  setStore("displayedPrograms", store.slices[0].filter(Boolean));

  function addNextSlice() {
    setStore("displayedPrograms", [
      ...store.displayedPrograms,
      ...store.slices[store.currentPage].filter(Boolean),
    ]);
  }

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      text: "Toute notre offre",
    };
    items.push(root);

    return items;
  });

  const programsOnMap = createMemo(() => {
    return props.results.programs
      .concat(props.results.programs_in_proximity)
      .filter(Boolean);
  });

  const criterions = createMemo<string[]>(() => {
    const items: string[] = [];

    // Rooms
    if (props.results.search_params.rooms) {
      const rooms: string[] = [];
      props.results.search_params.rooms.forEach((r) => {
        switch (r) {
          case "1":
            rooms.push("1 pièce");
            break;
          case "2":
          case "3":
          case "4":
            rooms.push(`${r} pièces`);
            break;
          case "5":
            rooms.push("5 pièces et +");
            break;
        }
      });

      items.push(rooms.join(", "));
    }

    // Budget
    if (props.results.search_params.budget) {
      if (props.results.search_params.budget >= 900000) {
        items.push("900 000 € et +");
      } else {
        items.push(`${formatPrice(props.results.search_params.budget)} max.`);
      }
    }

    // Lots types
    if (props.results.search_params.lot_types) {
      items.push(props.results.search_params.lot_types.join(", "));
    }

    // Regulations
    if (props.results.search_params.regulations) {
      items.push(props.results.search_params.regulations.join(", "));
    }

    // Sales states
    if (props.results.search_params.sale_states) {
      items.push(props.results.search_params.sale_states.join(", "));
    }

    return items;
  });

  const [lotProvider] = useLotActive();

  return (
    <>
      <div
        class="search-results-container"
        classList={{
          liste: viewList(),
          mixte: viewMixed(),
          carte: viewMap(),
        }}
      >
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>

        {/* Map */}
        <div class="map" data-test="map">
          <Show when={viewMap() || viewMixed()}>
            <div
              class="map-results"
              data-ga-zone="map"
              style={{ height: "100%" }}
            >
              <Map
                programs={programsOnMap()}
                searchParams={props.results.search_params}
              />
            </div>

            <SearchMapArrow />
          </Show>
        </div>

        <section class="search-results" data-test="results">
          {/* Arrows */}
          <SearchArrows />
          <div class="content-part">
            {/* Switch */}
            <Show when={viewportSizeProvider.viewPortIsMoreThan768}>
              <SearchSwitch />
            </Show>

            <SearchResultsHeader results={props.results} />

            {/* Results */}
            <section
              class="programs-list"
              data-ga-zone="list"
              data-test="programs"
            >
              <ProgramsListSearchResults
                programs={store.displayedPrograms}
                searchParams={props.results.search_params}
                showPromoCard={showPromoCard()}
              />

              <Show when={store.currentPage < store.slices.length - 1}>
                <div class="dynamic-pager">
                  <button
                    class="btn"
                    data-test="results-next"
                    onClick={() => {
                      setStore("currentPage", store.currentPage + 1);
                      addNextSlice();
                    }}
                  >
                    Voir la suite
                  </button>
                </div>
              </Show>
            </section>

            {/* More results */}
            <Show when={props.results.count_lots_more_results}>
              <section class="nearby" data-test="programs-nearby">
                <p class="title">
                  <strong>
                    {formatPlural(
                      props.results.count_lots_more_results,
                      "logement",
                    )}
                  </strong>{" "}
                  à proximité pourraient vous intéresser.
                </p>
                <div class="programs-list">
                  <ProgramsList
                    programs={props.results.programs_in_proximity}
                  />
                </div>
              </section>
            </Show>

            {/* Regions list */}
            <Show when={props.results.regions_list.length > 0}>
              <div class="regions-list" data-test="geographies">
                <h2>Retrouvez nos programmes immobiliers neufs en régions</h2>
                <ul>
                  <For each={props.results.regions_list}>
                    {(region) => (
                      <li data-test="geography">
                        <a href={region.url}>{region.title}</a>
                      </li>
                    )}
                  </For>
                </ul>
              </div>
            </Show>

            <PitchimmoBanner url={loc.pathname} />
          </div>

          <Show
            when={
              !props.results.search_params.city &&
              !props.results.search_params.department &&
              !props.results.search_params.region &&
              criterions().length === 0
            }
          >
            <ResultsSEO />
          </Show>

          <Breadcrumb items={breadcrumbItems()} />
          <div id="footer-alternate" />
        </section>
      </div>
    </>
  );
}

function SearchMapArrow() {
  return (
    <>
      <div class="arrow">
        <button
          type="button"
          aria-label="Mixte"
          class="btn btn-icon"
          onClick={() => {
            setViewList(false);
            setViewMixed(true);
            setViewMap(false);
          }}
          data-test="btn-expand-mixed"
        >
          <i aria-hidden="true" class="cog-icon">
            <IconKeyboardArrowLeft />
          </i>
        </button>
      </div>
    </>
  );
}

function SearchArrows() {
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <div class="arrows" data-test="arrows">
        <button
          type="button"
          aria-label="Liste"
          class="btn btn-icon"
          onClick={() => {
            setViewList(true);
            setViewMixed(false);
            setViewMap(false);
          }}
          data-test="btn-expand-results"
        >
          <i aria-hidden="true" class="cog-icon">
            <IconKeyboardArrowLeft />
          </i>
        </button>{" "}
        <Show when={!viewportSizeProvider.viewPortIsMoreThan768}>
          <button
            type="button"
            class="btn"
            onClick={() => {
              setViewList(false);
              setViewMixed(false);
              setViewMap(true);
            }}
            data-test="btn-expand-map"
          >
            Carte
            <i aria-hidden="true" class="cog-icon">
              <IconKeyboardArrowRight />
            </i>
          </button>
        </Show>
        <Show when={viewportSizeProvider.viewPortIsMoreThan768}>
          <Show
            when={viewMixed()}
            fallback={
              <button
                type="button"
                aria-label="Mixte"
                class="btn btn-icon"
                onClick={() => {
                  setViewList(false);
                  setViewMixed(true);
                  setViewMap(false);
                }}
                data-test="btn-expand-mixed"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowRight />
                </i>
              </button>
            }
          >
            <button
              type="button"
              aria-label="Carte"
              class="btn btn-icon"
              onClick={() => {
                setViewList(false);
                setViewMixed(false);
                setViewMap(true);
              }}
              data-test="btn-expand-map"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconKeyboardArrowRight />
              </i>
            </button>
          </Show>
        </Show>
      </div>
    </>
  );
}

function SearchSwitch() {
  return (
    <>
      <div class="switch" data-test="links">
        <button
          type="button"
          classList={{ active: viewList() }}
          onClick={() => {
            setViewList(true);
            setViewMixed(false);
            setViewMap(false);
          }}
          data-test="btn-expand-results"
        >
          Liste
        </button>{" "}
        <button
          type="button"
          classList={{ active: viewMixed() }}
          onClick={() => {
            setViewList(false);
            setViewMixed(true);
            setViewMap(false);
          }}
          data-test="btn-expand-mixed"
        >
          Mixte
        </button>{" "}
        <button
          type="button"
          classList={{ active: viewMap() }}
          onClick={() => {
            setViewList(false);
            setViewMixed(false);
            setViewMap(true);
          }}
          data-test="btn-expand-map"
        >
          Carte
        </button>
      </div>
    </>
  );
}

function SearchResultsHeader(props: { results: SearchResults }) {
  const criterions = createMemo<string[]>(() => {
    const items: string[] = [];

    // Rooms
    if (props.results.search_params.rooms) {
      const rooms: string[] = [];
      props.results.search_params.rooms.forEach((r) => {
        switch (r) {
          case "1":
            rooms.push("1 pièce");
            break;
          case "2":
          case "3":
          case "4":
            rooms.push(`${r} pièces`);
            break;
          case "5":
            rooms.push("5 pièces et +");
            break;
        }
      });

      items.push(rooms.join(", "));
    }

    // Budget
    if (props.results.search_params.budget) {
      if (props.results.search_params.budget >= 900000) {
        items.push("900 000 € et +");
      } else {
        items.push(`${formatPrice(props.results.search_params.budget)} max.`);
      }
    }

    // Lots types
    if (props.results.search_params.lot_types) {
      items.push(props.results.search_params.lot_types.join(", "));
    }

    // Regulations
    if (props.results.search_params.regulations) {
      items.push(props.results.search_params.regulations.join(", "));
    }

    // Sales states
    if (props.results.search_params.sale_states) {
      items.push(props.results.search_params.sale_states.join(", "));
    }

    return items;
  });

  const where = createMemo<string | undefined>(() => {
    if (props.results.search_params.city) {
      if (props.results.search_was_extended) {
        return `à proximité de <strong>${props.results.search_params.city}</strong>`;
      } else {
        return `à <strong>${props.results.search_params.city}</strong>`;
      }
    } else if (props.results.search_params.department) {
      return `${props.results.search_department_prefix} <strong>${props.results.search_params.department}</strong>`;
    } else if (props.results.search_params.region) {
      return `en <strong>${props.results.search_params.region}</strong>`;
    }
    return undefined;
  });

  return (
    <>
      <ResultsCount results={props.results} criterions={criterions()} />
      <p class="search-criterion" data-test="criterion">
        <Show when={where()}>
          <span innerHTML={where()} />
        </Show>
        <Show when={where() && criterions().length}> • </Show>
        {criterions().join(" • ")}
      </p>
    </>
  );
}

function ResultsCount(props: { results: SearchResults; criterions: string[] }) {
  const countItems = createMemo<string>(() => {
    const items: string[] = [];

    if (props.results.count_lots_apartments) {
      items.push(
        formatPlural(props.results.count_lots_apartments, "appartement"),
      );
    }

    if (props.results.count_lots_houses) {
      items.push(formatPlural(props.results.count_lots_houses, "maison"));
    }

    if (props.results.count_lots_plots) {
      items.push(formatPlural(props.results.count_lots_plots, "terrain"));
    }

    if (props.results.count_programs) {
      items.push(formatPlural(props.results.count_programs, "résidence"));
    }

    // Add <strong>
    items.forEach((item, index) => {
      items[index] = `<strong>${item}</strong>`;
    });

    if (items.length === 2) {
      items.splice(1, 0, " dans ");
    } else if (items.length === 3) {
      items.splice(1, 0, " et ");
      items.splice(3, 0, " dans ");
    } else if (items.length === 4) {
      items.splice(1, 0, ", ");
      items.splice(3, 0, " et ");
      items.splice(5, 0, " dans ");
    }

    return items.join("");
  });

  return (
    <>
      <Show
        when={
          props.results.search_params.city ||
          props.results.search_params.department ||
          props.results.search_params.region ||
          props.criterions.length
        }
        fallback={
          <>
            <h1 class="small">
              Retrouvez tous nos programmes immobiliers neufs
            </h1>
            <p class="h1" innerHTML={countItems()} />
          </>
        }
      >
        <h1 innerHTML={countItems()} />
      </Show>
    </>
  );
}
